import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Tasks from "../components/task/tasks";
import CreateTask from "../components/task/CreateTask";
import QuoteModal from "../components/quote/modal";
import InvoiceModal from "../components/invoice/modal";
import { getProjectOptions } from "../redux/reducerSlices/project";
import {
  Quote,
  Task,
  Client,
  Invoice,
  TaskRow,
} from "../components/task/types";
import {
  fetchSingleQuoteWithouState,
  getDraftQuotes,
} from "../redux/reducerSlices/quote";
import EstimateModal from "../components/task/EstimateModal";
import {
  fetchSingleInvoiceWithouState,
  getDraftInvoices,
} from "../redux/reducerSlices/invoice";
import { Release } from "../components/release/types";
import {
  fetchSingleReleaseWithouState,
  getDraftReleases,
} from "../redux/reducerSlices/release";
import ReleaseModal from "../components/release/modal";
import { refreshTasks } from "../redux/reducerSlices/task";
import { getPOsNotFullyInvoiced } from "../redux/reducerSlices/purchase-order";

interface ProjectClientMap {
  [key: string]: Client;
}

const TasksDashboard = () => {
  const dispatch = useDispatch();
  const {
    project: { projectOptions },
    quotes: { draftQuotes },
    user,
    invoices: { draftInvoices },
    releases: { draftReleases },
  } = useSelector((state: RootStateOrAny) => state);
  const userOrgRole = user?.selectedOrg.role;
  
  const [openModal, setOpenModal] = useState(false);
  const [modalTask, setModalTask] = useState<any>({});
  const [openQuoteModal, setOpenQuoteModal] = useState(false);
  const [modalQuoteTask, setModalQuoteTask] = useState<Task>(null);
  const [enableQuoteModal, setEnableQuoteModal] = useState<boolean>(false);
  const [modalQuote, setModalQuote] = useState<Quote>(null);
  const [projectCompanyMap, setProjectCompanyMap] = useState<ProjectClientMap>(
    {}
  );
  const [openEstimateModal, setOpenEstimateModal] = useState<boolean>(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [modalInvoiceTask, setModalInvoiceTask] = useState<TaskRow>(null);
  const [enableInvoiceModal, setEnableInvoiceModal] = useState<boolean>(false);
  const [modalInvoice, setModalInvoice] = useState<Invoice>(null);
  const [enableReleaseModal, setEnableReleaseModal] = useState<boolean>(false);
  const [modalRelease, setModalRelease] = useState<Release>(null);
  const [openReleaseModal, setOpenReleaseModal] = useState<boolean>(false);
  const [modalReleaseTask, setModalReleaseTask] = useState<TaskRow>(null);

  useEffect(() => {
    dispatch(getProjectOptions());

    if (userOrgRole === "Admin") {
      dispatch(getPOsNotFullyInvoiced());
      dispatch(getDraftQuotes());
      setEnableQuoteModal(true);
      dispatch(getDraftInvoices());
      setEnableInvoiceModal(true);
      dispatch(getDraftReleases());
      setEnableReleaseModal(true);
    }
  }, [dispatch, userOrgRole]);

  useEffect(() => {
    if (userOrgRole === "Admin") {
      const pcMap: ProjectClientMap = {};

      projectOptions.forEach((project: any) => {
        pcMap[project.id] = project.company;
      });

      setProjectCompanyMap(pcMap);
    }
  }, [projectOptions, userOrgRole]);

  const openNewTaskModal = () => {
    setModalTask({});
    setOpenModal(true);
  };

  const handleOpenEdinTaskModal = (task: any) => {
    setModalTask(task);
    setOpenModal(true);
  };

  const handleOpenQuoteModal = async (task: any, quote: any) => {
    if (quote) {
      const singleQuote = await fetchSingleQuoteWithouState(quote.id);

      setModalQuote(singleQuote);
    } else {
      setModalQuote(null);
    }

    setModalQuoteTask(task);
    setOpenQuoteModal(true);
  };

  const handleOpenEstimateModal = async (task: any) => {
    setModalTask(task);
    setOpenEstimateModal(true);
  };

  const handleOpenInvoiceModal = async (task: any, invoice: any) => {
    if (invoice) {
      const singleInvoice = await fetchSingleInvoiceWithouState(
        invoice.id,
        user
      );

      setModalInvoice(singleInvoice);
    } else {
      setModalInvoice(null);
    }
    
    setModalInvoiceTask(task);
    setOpenInvoiceModal(true);
  };

  const handleOpenReleaseModal = async (task: any, release: any) => {
    if (release) {
      const singleRelease = await fetchSingleReleaseWithouState(release.id);

      setModalRelease(singleRelease);
    } else {
      setModalRelease(null);
      setModalReleaseTask(task);
    }

    setOpenReleaseModal(true);
  };

  return (
    <Container>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
          marginBottom: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h6">
            <FormattedMessage id="general.Tasks" defaultMessage="Tasks" />
          </Typography>
          <Button onClick={openNewTaskModal}>
            <FormattedMessage id="general.New-Task" defaultMessage="New Task" />
          </Button>
        </Box>
        <CreateTask
          openModal={openModal}
          setOpenModal={setOpenModal}
          task={modalTask}
          projects={projectOptions}
        />
        <EstimateModal
          openModal={openEstimateModal}
          setOpenModal={setOpenEstimateModal}
          task={modalTask}
        />
        {enableQuoteModal && (
          <QuoteModal
            openModal={openQuoteModal}
            setOpenModal={setOpenQuoteModal}
            task={modalQuoteTask}
            extQuote={modalQuote}
            company={projectCompanyMap[modalQuoteTask?.project?.id]}
          />
        )}
        {enableInvoiceModal && (
          <InvoiceModal
            openModal={openInvoiceModal}
            setOpenModal={setOpenInvoiceModal}
            task={modalInvoiceTask}
            extInvoice={modalInvoice}
            company={projectCompanyMap[modalInvoiceTask?.project?.id]}
          />
        )}
        {enableReleaseModal && (
          <ReleaseModal
            openModal={openReleaseModal}
            setOpenModal={setOpenReleaseModal}
            task={modalReleaseTask}
            extRelease={modalRelease}
            onCreateCB={() => {
              dispatch(refreshTasks());
            }}
          />
        )}
        <Tasks
          handleOpenEdinTaskModal={handleOpenEdinTaskModal}
          handleOpenQuoteModal={handleOpenQuoteModal}
          handleOpenInvoiceModal={handleOpenInvoiceModal}
          draftQuotes={draftQuotes}
          draftInvoices={draftInvoices}
          handleOpenEstimateModal={handleOpenEstimateModal}
          draftReleases={draftReleases}
          handleOpenReleaseModal={handleOpenReleaseModal}
        />
      </Box>
    </Container>
  );
};

export default TasksDashboard;
