import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";

const Row: React.FC<{
  release: {
    status: String;
    id?: String;
    handleOpenEdinModal: any;
    date: string;
    project: any;
  };
}> = ({ release }) => {
  const { id, status, handleOpenEdinModal, date, project } = release;
  const handleEditQuote = () => {
    handleOpenEdinModal(release);
  };

  const renderStatus = () => {
    switch (status) {
      case "draft":
        return (
          <FormattedMessage id="general-status.draft" defaultMessage="draft" />
        );
      case "released":
        return (
          <FormattedMessage
            id="general-status.released"
            defaultMessage="released"
          />
        );
      case "canceled":
        return (
          <FormattedMessage
            id="general-status.canceled"
            defaultMessage="canceled"
          />
        );
      default:
        console.error("unexpected status: ", status);
        return (
          <FormattedMessage
            id="general-status.unexpected-status"
            defaultMessage="unexpected status"
          />
        );
    }
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }}
      style={{ cursor: "pointer" }}
      onClick={(event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        event.nativeEvent.stopPropagation();
        handleEditQuote();
      }}
    >
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {project?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {date}
      </TableCell>
      <TableCell component="th" scope="row">
        {renderStatus()}
      </TableCell>
    </TableRow>
  );
};

export default Row;
