import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Quote, QuoteItem, Task } from "../task/types";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  getCompanySubscriptionsItems,
  getCompanyTasks,
} from "../../redux/reducerSlices/company";
import { Add, AssignmentTurnedIn } from "@mui/icons-material";

const QuoteItemModal: React.FC<{
  openModal: boolean;
  setOpenModal: any;
  qi: QuoteItem;
  updateQi: (qi: QuoteItem) => void;
  company: any;
  setModalTask: (qi: QuoteItem) => void;
  quote: Quote;
}> = ({
  openModal,
  setOpenModal,
  qi,
  updateQi,
  company,
  setModalTask,
  quote,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { companyState } = useSelector((state: RootStateOrAny) => state);
  const { companies } = companyState;
  const selectedCompany = companies.find((c: any) => c.id === company?.id);

  const [uQi, setUQi] = useState<QuoteItem | null>(null);

  useEffect(() => {
    setUQi(qi);
  }, [qi]);

  useEffect(() => {
    if (selectedCompany && !selectedCompany.tasks) {
      dispatch(getCompanyTasks(selectedCompany.id));
      dispatch(getCompanySubscriptionsItems(selectedCompany.id));
    }
  }, [selectedCompany, dispatch]);

  const handleClose = () => {
    setOpenModal(false);
    setUQi(null);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    let title = uQi?.title;

    if (title === "New quote item") {
      if (uQi?.task?.id) {
        title = uQi?.task?.title;
      } else if (uQi?.subscription_item?.id) {
        title = `Quote #${quote.number} ${uQi?.subscription_item?.title}`;
      }
    }

    updateQi({
      ...uQi,
      title,
    });
    handleClose();
  };

  return (
    <div style={{ width: 600 }}>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={openModal}
        onClose={() => handleClose()}
      >
        <DialogTitle>
          <FormattedMessage
            id="quote-item-modal.Edit-quote-item"
            defaultMessage="Edit quote item"
          />
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="title"
                  label={
                    <FormattedMessage
                      id="general.Title"
                      defaultMessage="Title"
                    />
                  }
                  type="text"
                  name="title"
                  variant="outlined"
                  value={uQi?.title || ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    setUQi({ ...uQi, title: event.target.value });
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  size="small"
                  id="description"
                  label={
                    <FormattedMessage
                      id="general.Description"
                      defaultMessage="Description"
                    />
                  }
                  type="text"
                  name="description"
                  variant="outlined"
                  value={uQi?.description || ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    setUQi({ ...uQi, description: event.target.value });
                  }}
                />
              </Grid>
              <Grid
                item
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Autocomplete
                  style={{
                    marginRight: 5,
                  }}
                  fullWidth
                  size="small"
                  value={uQi?.task || null}
                  options={selectedCompany?.tasks || []}
                  getOptionLabel={(option: any) => option.title || ""}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.title}
                      </li>
                    );
                  }}
                  onChange={(_event, value) => {
                    const task = value as Task;

                    setUQi({
                      ...uQi,
                      subscription_item: null,
                      task,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "general.Select-a-task",
                        defaultMessage: "Select a task",
                      })}
                      variant="outlined"
                    />
                  )}
                />
                <IconButton
                  onClick={() => setModalTask(uQi)}
                  title={
                    Boolean(uQi?.task?.id)
                      ? intl.formatMessage({
                          id: "general.Edit-task",
                          defaultMessage: "Edit task",
                        })
                      : intl.formatMessage({
                          id: "general.Create-task",
                          defaultMessage: "Create task",
                        })
                  }
                >
                  {Boolean(uQi?.task?.id) ? <AssignmentTurnedIn /> : <Add />}
                </IconButton>
              </Grid>
              <Grid
                item
                sm={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Autocomplete
                  style={{
                    marginRight: 5,
                  }}
                  fullWidth
                  size="small"
                  disabled={Boolean(uQi?.task?.id)}
                  value={uQi?.subscription_item || null}
                  options={selectedCompany?.subscription_items || []}
                  getOptionLabel={(option: any) => option.title || ""}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.title}
                      </li>
                    );
                  }}
                  onChange={(_event, value: any) => {
                    const subscription_item = value;

                    setUQi({ ...uQi, subscription_item });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        Boolean(uQi?.task?.id)
                          ? intl.formatMessage({
                              id: "general.You-cannot-have-a-task-and-a-subscription-item",
                              defaultMessage:
                                "You cannot have a task and a subscription item",
                            })
                          : intl.formatMessage({
                              id: "general.Select-a-subscription-item",
                              defaultMessage: "Select a subscription item",
                            })
                      }
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 24px 20px",
            }}
          >
            <div>{/* empty */}</div>
            <div>
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleClose();
                }}
              >
                <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
              </Button>
              <Button type="submit">
                <FormattedMessage id="general.Update" defaultMessage="Update" />
              </Button>
            </div>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default QuoteItemModal;
