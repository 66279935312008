import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

const ConfirmDialog: React.FC<{
  confirmHandler: () => void;
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
  text: string;
  loading?: boolean;
}> = (props) => {
  const {
    confirmHandler,
    openModal,
    setOpenModal,
    text,
    loading = false,
  } = props;

  return (
    <Dialog
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <FormattedMessage
          id="general.Confirm-the-action"
          defaultMessage="Confirm the action"
        />
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 56.5,
              width: 600,
              maxWidth: "100%",
            }}
          >
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Typography>{text}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          disabled={loading}
          onClick={(event) => {
            event.stopPropagation();
            confirmHandler();
          }}
          color="error"
          variant="contained"
        >
          <FormattedMessage id="general.Confirm" defaultMessage="Confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
