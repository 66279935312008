import React, { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useTheme,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import Paper from "@mui/material/Paper";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { getTasks, reorderTasks } from "../../redux/reducerSlices/task";
import Task from "./task";
import SubscriptionRow from "./SubscriptionRow";
import { getSubscriptionsToInvoice } from "../../redux/reducerSlices/subscription";

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: `${grid * 2}px ${grid * 3}px`,
  margin: `0 0 ${grid}px 0`,
  borderRadius: 5,
  background: "white",

  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightgrey" : "white",
  padding: grid,
  // width: 350,
});

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Tasks = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const intl = useIntl();

  const {
    task: tasksState,
    user,
    subscriptionState,
    project: { projectOptions },
  } = useSelector((state: RootStateOrAny) => state);
  const { tasks, pagination } = tasksState;
  const { subscriptions, pagination: subsPagination } = subscriptionState;
  const userOrgRole = user?.selectedOrg.role;

  const [tabIndex, setTabIndex] = useState(0);
  const [enableSortHandler, setEnableSortHandler] = useState(false);

  const pageSizeQuery = parseInt(query.get("pageSize")) || 10;
  const pageQuery = parseInt(query.get("page")) || 1;
  const projectIdQuery = query.get("projectId") || "";

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabIndex: number
  ) => {
    let sortHandler = false;

    const pIdQ = projectIdQuery ? `?projectId=${projectIdQuery}` : "";

    if (userOrgRole === "Admin") {
      if (newTabIndex === 0) {
        history.push(`/tasks${pIdQ}`);
      } else if (newTabIndex === 1) {
        history.push(`/tasks/review${pIdQ}`);
      } else if (newTabIndex === 2) {
        history.push(`/tasks/add-to-quote${pIdQ}`);
      } else if (newTabIndex === 3) {
        history.push(`/tasks/add-to-release${pIdQ}`);
      } else if (newTabIndex === 4) {
        history.push(`/tasks/add-to-invoice${pIdQ}`);
      } else if (newTabIndex === 5) {
        history.push(`/tasks/to-do${pIdQ}`);
        sortHandler = true;
      } else if (newTabIndex === 6) {
        history.push(`/tasks/my-to-do${pIdQ}`);
      } else if (newTabIndex === 7) {
        history.push(`/tasks/in-progress${pIdQ}`);
      } else if (newTabIndex === 8) {
        history.push(`/tasks/my-in-progress${pIdQ}`);
      } else if (newTabIndex === 9) {
        history.push(`/tasks/to-estimate${pIdQ}`);
      } else if (newTabIndex === 10) {
        history.push(`/tasks/my-to-estimate${pIdQ}`);
      }
    } else if (userOrgRole === "Developer") {
      if (newTabIndex === 0) {
        history.push(`/tasks${pIdQ}`);
      } else if (newTabIndex === 1) {
        history.push(`/tasks/review${pIdQ}`);
      } else if (newTabIndex === 2) {
        history.push(`/tasks/to-do${pIdQ}`);
      } else if (newTabIndex === 3) {
        history.push(`/tasks/in-progress${pIdQ}`);
      } else if (newTabIndex === 4) {
        history.push(`/tasks/to-estimate${pIdQ}`);
      }
    }

    setEnableSortHandler(sortHandler);
  };

  useEffect(() => {
    let sortHandler = false;

    if (history.location.pathname === "/tasks") {
      setTabIndex(0);
      dispatch(
        getTasks({
          page: pageQuery,
          pageSize: pageSizeQuery,
          showAll: true,
          selectProjectId: projectIdQuery,
        })
      );
    } else {
      if (userOrgRole === "Admin") {
        if (history.location.pathname === "/tasks/review") {
          setTabIndex(1);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              showInReview: true,
              selectProjectId: projectIdQuery,
            })
          );
        } else if (history.location.pathname === "/tasks/add-to-quote") {
          setTabIndex(2);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              showAddToQuote: true,
              selectProjectId: projectIdQuery,
            })
          );
        } else if (history.location.pathname === "/tasks/add-to-release") {
          setTabIndex(3);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              showAddToRelease: true,
              selectProjectId: projectIdQuery,
            })
          );
        } else if (history.location.pathname === "/tasks/add-to-invoice") {
          setTabIndex(4);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showAddToInvoice: true,
            })
          );
        } else if (history.location.pathname === "/tasks/to-do") {
          sortHandler = true;
          setTabIndex(5);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showToDo: true,
            })
          );
        } else if (history.location.pathname === "/tasks/my-to-do") {
          sortHandler = true;
          setTabIndex(6);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showMyToDo: true,
            })
          );
        } else if (history.location.pathname === "/tasks/in-progress") {
          setTabIndex(7);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showInProgress: true,
            })
          );
        } else if (history.location.pathname === "/tasks/my-in-progress") {
          setTabIndex(8);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showMyInProgress: true,
            })
          );
        } else if (history.location.pathname === "/tasks/to-estimate") {
          setTabIndex(9);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showToEstimate: true,
            })
          );
        } else if (history.location.pathname === "/tasks/my-to-estimate") {
          setTabIndex(10);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showMyToEstimate: true,
            })
          );
        }

        setEnableSortHandler(sortHandler);
      } else if (userOrgRole === "Developer") {
        if (history.location.pathname === "/tasks/review") {
          setTabIndex(1);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              showInReview: true,
              selectProjectId: projectIdQuery,
            })
          );
        } else if (history.location.pathname === "/tasks/to-do") {
          setTabIndex(2);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showToDo: true,
            })
          );
        } else if (history.location.pathname === "/tasks/in-progress") {
          setTabIndex(3);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showInProgress: true,
            })
          );
        } else if (history.location.pathname === "/tasks/to-estimate") {
          setTabIndex(4);
          dispatch(
            getTasks({
              page: pageQuery,
              pageSize: pageSizeQuery,
              selectProjectId: projectIdQuery,
              showToEstimate: true,
            })
          );
        }
      }
    }
  }, [
    dispatch,
    setTabIndex,
    history.location.pathname,
    pageSizeQuery,
    pageQuery,
    userOrgRole,
    projectIdQuery,
  ]);

  const handleSubsChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getSubscriptionsToInvoice(newPage + 1, subsPagination.pageSize));
  };

  const handleSubsChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(getSubscriptionsToInvoice(1, parseInt(event.target.value, 10)));
  };

  useEffect(() => {
    if (tabIndex === 4 && userOrgRole === "Admin") {
      dispatch(getSubscriptionsToInvoice());
    }
  }, [userOrgRole, tabIndex, dispatch]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const pIdQ = projectIdQuery ? `&projectId=${projectIdQuery}` : "";
    history.push({
      pathname: history.location.pathname,
      search: `?page=${newPage + 1}&pageSize=${pagination.pageSize}${pIdQ}`,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const pIdQ = projectIdQuery ? `&projectId=${projectIdQuery}` : "";
    history.push({
      pathname: history.location.pathname,
      search: `?page=0&pageSize=${parseInt(event.target.value, 10)}${pIdQ}`,
    });
  };

  const renderTable = () => {
    if (tasksState.loading) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            minHeight: 100,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Table
                  sx={{
                    minWidth: 100,
                  }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="general.Task"
                          defaultMessage="Task"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormattedMessage
                          id="general.Status"
                          defaultMessage="Status"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormattedMessage
                          id="general.Type"
                          defaultMessage="Type"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormattedMessage
                          id="general.Project"
                          defaultMessage="Project"
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormattedMessage
                          id="general.Developer"
                          defaultMessage="Developer"
                        />
                      </TableCell>
                      {tabIndex === 4 && userOrgRole === "Admin" && (
                        <TableCell align="right">
                          <FormattedMessage
                            id="general.Remaining"
                            defaultMessage="Remaining"
                          />
                        </TableCell>
                      )}
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(tasks.length) ? (
                      tasks.map((task: any, index: number) => (
                        <Draggable
                          key={task.id}
                          draggableId={String(task.id)}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <Task
                              enableSortHandler={enableSortHandler}
                              forwardRef={provided.innerRef}
                              {...provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              isDragging={snapshot.isDragging}
                              key={task.id}
                              task={{
                                ...task,
                                handleOpenEdinTaskModal:
                                  props.handleOpenEdinTaskModal,
                                handleOpenQuoteModal:
                                  props.handleOpenQuoteModal,
                                draftQuotes: props.draftQuotes,
                                handleOpenEstimateModal:
                                  props.handleOpenEstimateModal,
                                handleOpenInvoiceModal:
                                  props.handleOpenInvoiceModal,
                                draftInvoices: props.draftInvoices,
                                handleOpenReleaseModal:
                                  props.handleOpenReleaseModal,
                                draftReleases: props.draftReleases,
                                isInToInvoiceTab:
                                  tabIndex === 4 && userOrgRole === "Admin",
                              }}
                            />
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <Box>
                          <Typography style={{ padding: 20 }}>
                            <FormattedMessage
                              id="general.No-tasks-found"
                              defaultMessage="No tasks found!"
                            />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {provided.placeholder}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <Box
                        style={{
                          marginTop: 8,
                          marginLeft: 4,
                        }}
                      >
                        <FormControl
                          fullWidth
                          size="small"
                          style={{
                            width: 200,
                            maxWidth: "100%",
                          }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            <FormattedMessage
                              id="general.Select-project"
                              defaultMessage="Select project"
                            />
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={projectIdQuery || ""}
                            onChange={(e) => {
                              const pIdQ = e.target.value
                                ? `&projectId=${e.target.value}`
                                : "";
                              history.push({
                                pathname: history.location.pathname,
                                search: `?page=0&pageSize=${pagination.pageSize}${pIdQ}`,
                              });
                            }}
                            label={
                              <FormattedMessage
                                id="general.Select-project"
                                defaultMessage="Select project"
                              />
                            }
                          >
                            <MenuItem value="">
                              <em>
                                <FormattedMessage
                                  id="general.All-projects"
                                  defaultMessage="All projects"
                                />
                              </em>
                            </MenuItem>
                            {Boolean(projectOptions.length) &&
                              projectOptions.map((project: any) => (
                                <MenuItem value={project.id}>
                                  {project.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>

                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={pagination.total}
                        rowsPerPage={pagination.pageSize}
                        page={pagination.page - 1}
                        style={{
                          borderBottom: 0,
                        }}
                        SelectProps={{
                          inputProps: {
                            "aria-label": intl.formatMessage({
                              id: "general.Tasks-per-page",
                              defaultMessage: "Tasks per page",
                            }),
                          },
                          native: true,
                        }}
                        labelRowsPerPage={intl.formatMessage({
                          id: "general.Tasks-per-page",
                          defaultMessage: "Tasks per page",
                        })}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => {
                          return `${from}-${to} ${intl.formatMessage({
                            id: "pagination.of",
                            defaultMessage: "of",
                          })} ${count}`;
                        }}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

  const onDragEnd = (event: any) => {
    if (!event.destination) {
      return null;
    }

    dispatch(
      reorderTasks({
        sourceIndex: event.source.index,
        destinationIndex: event.destination.index,
      })
    );
  };

  const renderSubscriptionsTable = () => {
    if (userOrgRole !== "Admin") return null;
    if (tabIndex !== 4) return null;

    if (!subscriptions.length) {
      return (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6" component="h6">
            <FormattedMessage
              id="general.No-subscriptions-to-invoice"
              defaultMessage="No subscriptions to invoice"
            />
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h6">
          <FormattedMessage
            id="general.Subscriptions"
            defaultMessage="Subscriptions"
          />
        </Typography>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 100,
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="general.Title" defaultMessage="Title" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Client"
                    defaultMessage="Client"
                  />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Start-Date"
                    defaultMessage="Start Date"
                  />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage
                    id="general.Renewal-Frequency"
                    defaultMessage="Renewal Frequency"
                  />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="general.Price" defaultMessage="Price" />
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions &&
                subscriptions.map((subscription) => (
                  <SubscriptionRow
                    key={subscription.id}
                    subscription={subscription}
                    handleOpenInvoiceModal={props.handleOpenInvoiceModal}
                    draftInvoices={props.draftInvoices}
                  />
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={subsPagination.total}
                  rowsPerPage={subsPagination.pageSize}
                  page={subsPagination.page - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": intl.formatMessage({
                        id: "general.Subscriptions-per-page",
                        defaultMessage: "Subscriptions per page",
                      }),
                    },
                    native: true,
                  }}
                  labelRowsPerPage={intl.formatMessage({
                    id: "general.Subscriptions-per-page",
                    defaultMessage: "Subscriptions per page",
                  })}
                  onPageChange={handleSubsChangePage}
                  onRowsPerPageChange={handleSubsChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}-${to} ${intl.formatMessage({
                      id: "pagination.of",
                      defaultMessage: "of",
                    })} ${count}`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={intl.formatMessage({
                  id: "general.All-Tasks",
                  defaultMessage: "All Tasks",
                })}
              />
              {["Admin", "Developer"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.To-Review",
                    defaultMessage: "To Review",
                  })}
                />
              )}
              {userOrgRole === "Admin" && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.To-Quote",
                    defaultMessage: "To Quote",
                  })}
                />
              )}
              {userOrgRole === "Admin" && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.To-Release",
                    defaultMessage: "To Release",
                  })}
                />
              )}
              {userOrgRole === "Admin" && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.To-Invoice",
                    defaultMessage: "To Invoice",
                  })}
                />
              )}
              {["Admin", "Developer"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "task.status.To-Do",
                    defaultMessage: "To Do",
                  })}
                />
              )}
              {["Admin"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.My-To-Do",
                    defaultMessage: "My To Do",
                  })}
                />
              )}
              {["Admin", "Developer"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "task.status.In-Progress",
                    defaultMessage: "In Progress",
                  })}
                />
              )}
              {["Admin"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.My-In-Progress",
                    defaultMessage: "My In Progress",
                  })}
                />
              )}
              {["Admin", "Developer"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "task.status.To-Estimate",
                    defaultMessage: "To Estimate",
                  })}
                />
              )}
              {["Admin"].includes(userOrgRole) && (
                <Tab
                  label={intl.formatMessage({
                    id: "general.My-To-Estimate",
                    defaultMessage: "My To Estimate",
                  })}
                />
              )}
            </Tabs>
          </Box>
        </Box>
        {renderTable()}
      </TableContainer>

      {renderSubscriptionsTable()}
    </React.Fragment>
  );
};

export default Tasks;
