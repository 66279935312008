import { Client, InvoiceToClient } from "../company/types";
import { Release } from "../release/types";

enum Currency {
  SEK = "SEK",
  CAD = "CAD",
  USD = "USD",
}

enum TaskStatus {
  GatheringData = "Gathering Data",
  ToEstimate = "To Estimate",
  PendingApproval = "Pending Approval",
  ToDo = "To Do",
  InProgress = "In Progress",
  InReview = "In Review",
  InStaging = "In Staging",
  Closed = "Closed",
}

interface TaskLabel {
  color: 'red' | 'blue' | 'black',
  name?: string
}

interface TaskHitory {
  title: string;
  description: string;
  status: TaskStatus;
  trello_id?: string;
  trello_url?: string;
  trello_board_id?: string;
}

interface Task {
  id?: number;
  title: string;
  description?: string;
  status: TaskStatus;
  project?: any;
  trello_id?: string;
  trello_id_short?: number;
  trello_url?: string;
  task_histories?: [TaskHitory];
  trello_board_id?: string;
  ticket?: any;
  quote_items?: [any];
  estimates?: [any];
  preliminary_notes?: string;
  seen_by?: any;
  due_date?: string;
  hasChanges?: boolean;
  invoicing_scheme?: string;
}

interface TaskRow {
  trello_id_short: any;
  title?: string;
  status: TaskStatus;
  label: TaskLabel;
  id?: number;
  handleOpenEdinTaskModal: any;
  handleOpenQuoteModal: any;
  trello_url: string;
  project: any;
  developer?: any;
  draftQuotes: [any];
  quote_items: [any];
  quoteItemsMap: {};
  addToQuoteTabCB?: () => void;
  invoice_items: [any];
  invoiceItemsMap: {};
  handleOpenInvoiceModal: any;
  draftInvoices: [any];
  addToInvoiceCB?: () => void;
  invoiced_total?: number;
  invoicing_scheme?: string;
  quoted_total?: number;
  partial_invoicing?: number;
  isInToInvoiceTab?: boolean;
  estimates: [Estimate];
  handleOpenEstimateModal: any;
  handleOpenReleaseModal: any;
  draftReleases: [any];
  addToReleaseCB?: () => void;
  release?: Release;
  subscription_items: [any];
  company: any;
  preliminary_notes?: string;
  seen_by: [any];
  blocked_by_tasks: [any];
  blocking_tasks: [any];
  due_date?: string;
}

enum QuoteStatus {
  draft = "draft",
  sent = "sent",
  closed = "closed",
}

enum QuoteItemStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
  canceled = "canceled",
}

interface QuoteItem {
  id: number;
  price: number;
  status: QuoteItemStatus;
  task?: Task;
  title: string;
  description: string;
  order: number;
  subscription_item?: SubscriptionItem;
}

interface Quote {
  id?: number;
  number: number;
  date: Date | null;
  expiration_date: Date | null;
  expiration_days: number;
  currency: Currency;
  status: QuoteStatus;
  base_rate: number;
  partial_invoicing: number;
  company?: Client;
  quote_items?: QuoteItem[];
  tickets?: [any];
  delivery_time?: number;
}

interface PurchaseOrder {
  id: number;
  number: string;
  summary: string;
  amount: number;
  total_invoiced?: number;
  invoices?: Invoice;
}

interface PurchaseOrdersMap {
  [key: string]: PurchaseOrder;
}

enum InvoiceStatus {
  draft = "draft",
  sent = "sent",
  closed = "closed",
}

interface InvoiceItem {
  id: number;
  price: number;
  title?: string;
  task?: Task;
  invoice?: Invoice;
  subscription_item?: any;
  order: number;
  is_markup_discount?: boolean;
}

interface Invoice {
  id?: number;
  number: number;
  date: Date | null;
  currency: Currency;
  status: InvoiceStatus;
  company?: Client;
  invoice_items?: InvoiceItem[];
  tickets?: [any];
  purchase_order?: PurchaseOrder;
  invoice_to?: InvoiceToClient;
  markup_percentage?: number;
}

interface Estimate {
  id?: number;
  hours: number;
  comments?: string;
  user?: any;
}

interface SubscriptionItem {
  id: number;
  title: string;
  price: number;
  start_date: Date;
  end_date: Date;
  invoice_item?: InvoiceItem;
}

interface Subscription {
  id: number;
  title: string;
  company: Client;
  start_date: Date;
  renewal_frequency: "monthly" | "yearly";
  price: number;
  invoice_items: InvoiceItem[];
  invoiceItemsMap: {};
  subscription_items?: SubscriptionItem[];
}

interface SubsPagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}

export type {
  Task,
  TaskStatus,
  TaskHitory,
  Quote,
  Client,
  QuoteItem,
  Estimate,
  Invoice,
  InvoiceItem,
  TaskRow,
  PurchaseOrder,
  PurchaseOrdersMap,
  Subscription,
  SubsPagination,
};

export { QuoteStatus, Currency, QuoteItemStatus, InvoiceStatus };
