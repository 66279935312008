import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import {
  createProject,
  deleteProject,
  updateProject,
} from "../../redux/reducerSlices/project";
import ConfirmDialog from "../ConfirmDialog";
import { connectTrello } from "../../redux/reducerSlices/trello";
import axios from "axios";
import { refreshMe } from "../../redux/reducerSlices/me";
import { updateLayout } from "../../redux/reducerSlices/layout";
import { toast } from "react-hot-toast";
import { Client } from "../task/types";

interface CompaniesMap {
  [key: string]: Client;
}

const Modal: React.FC<{
  project?: any;
  ownsCurrOrg: any;
  onCloseModal: () => void;
}> = ({ project: currProject, onCloseModal, ownsCurrOrg }) => {
  const isEdit = Boolean(currProject.id);
  const {
    user,
    trello,
    layout,
    inviteState: { developers },
  } = useSelector((state: any) => state);

  const allDevs = [...developers];

  const openModal = layout.openProjectModal;
  const setOpenModal = (open: boolean) => {
    dispatch(
      updateLayout({
        openProjectModal: open,
      })
    );
  };

  const emptyProject = {
    name: "",
    description: "",
    status: "open",
    project: {
      name: "",
    },
    trello_id: "",
    trelloBoard: {},
    developers: [],
    company: null,
    default_invoicing_scheme: "Fixed",
    archived: false,
  };

  const dispatch = useDispatch();
  const intl = useIntl();
  const { companyState } = useSelector((state: RootStateOrAny) => state);

  const [project, setProject] = useState(emptyProject);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasDevsChanges, setHasDevsChanges] = useState(false);

  const selectedBoard = trello.boards.find(
    (b: any) => b.id === project.trello_id
  );

  const { companies } = companyState;
  const companiesMap: CompaniesMap = {};

  companies.forEach((company: Client) => {
    companiesMap[company.id] = company;
  });

  useEffect(() => {
    if (currProject.id) {
      setProject(currProject);
    }
  }, [currProject]);

  const handleDelete = (projectId: any) => {
    dispatch(deleteProject(projectId));
    setOpenConfirmModal(false);
    resetState();
  };

  const handleOnChangeFormField = (event, name = null) => {
    if (name === null) {
      setProject({ ...project, [event.target.name]: event.target.value });
    } else {
      setProject({ ...project, [name]: event.toString() });
    }
  };

  const validateProject = () => {
    if (!project.name) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!validateProject()) {
      setShowFormErrors(true);
      return null;
    }

    setLoading(true);
    setProject({ ...project });

    const projectToSave = {
      data: {
        ...project,
        organization: user.selected_organization_id,
        hasDevsChanges,
      },
    };

    if (isEdit) {
      await dispatch(updateProject(projectToSave));
    } else {
      await dispatch(createProject(projectToSave));
    }

    resetState();
  };

  const resetState = () => {
    setShowFormErrors(false);
    setProject(emptyProject);
    setOpenModal(false);
    setLoading(false);
  };

  const renderDialogActions = () => {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 56.5,
            minWidth: 400,
            marginBottom: 20,
          }}
        >
          <CircularProgress color="primary" />
        </div>
      );
    }

    return (
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 24px 20px",
        }}
      >
        <div>
          {isEdit && (
            <Button
              onClick={(event) => {
                event.stopPropagation();
                setOpenConfirmModal(true);
              }}
              color="error"
            >
              <FormattedMessage
                id="general.Delete-Project"
                defaultMessage="Delete Project"
              />
            </Button>
          )}
        </div>
        <div>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              resetState();
              onCloseModal();
            }}
          >
            <FormattedMessage id="general.Cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            {isEdit ? (
              <FormattedMessage id="general.Update" defaultMessage="Update" />
            ) : (
              <FormattedMessage id="general.Create" defaultMessage="Create" />
            )}
          </Button>
        </div>
      </DialogActions>
    );
  };

  const connectOrgToTrello = async () => {
    await axios.put(`/owned-organizations/${user.selectedOrg.id}`, {
      trello_token: user.trello_token,
    });

    dispatch(refreshMe());

    toast.success(
      intl.formatMessage({
        id: "general.Organization-connected",
        defaultMessage: "Organization connected",
      })
    );
  };

  const renderTrelloBoards = () => {
    if (!ownsCurrOrg) return null;

    if (!user.trello_token) {
      return (
        <Button
          onClick={() => {
            dispatch(connectTrello());
          }}
        >
          <FormattedMessage
            id="general.Connect-Trello"
            defaultMessage="Connect Trello"
          />
        </Button>
      );
    }

    if (!user.selectedOrg?.trello_token) {
      return (
        <Button onClick={connectOrgToTrello}>
          <FormattedMessage
            id="general.Connect-organization-to-Trello"
            defaultMessage="Connect organization to Trello"
          />
        </Button>
      );
    }

    return (
      <Autocomplete
        fullWidth
        value={selectedBoard || null}
        options={trello.boards}
        getOptionLabel={(option: any) => option.name || ""}
        style={{ marginTop: 10 }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        onChange={(_event, value) => {
          handleOnChangeFormField(value?.id || "", "trello_id");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({
              id: "general.Trello-Board",
              defaultMessage: "Trello Board",
            })}
            variant="outlined"
          />
        )}
      />
    );
  };

  return (
    <div>
      <Dialog
        open={openModal}
        onClose={() => {
          resetState();
          onCloseModal();
        }}
      >
        <DialogTitle>
          {isEdit ? (
            <FormattedMessage
              id="general.Edit-Project"
              defaultMessage="Edit Project"
            />
          ) : (
            <FormattedMessage
              id="general.New-Project"
              defaultMessage="New Project"
            />
          )}
        </DialogTitle>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <DialogContent
            style={{
              width: "500px",
              maxWidth: "100%",
            }}
          >
            <TextField
              autoFocus
              value={project.name}
              id="title"
              label={intl.formatMessage({
                id: "general.Title",
                defaultMessage: "Title",
              })}
              type="text"
              name="name"
              fullWidth
              required
              variant="outlined"
              error={showFormErrors && project.name === ""}
              helperText={
                showFormErrors && project.name === ""
                  ? intl.formatMessage({
                      id: "general.Title-is-required",
                      defaultMessage: "Title is required",
                    })
                  : ""
              }
              onChange={(event) => handleOnChangeFormField(event)}
            />
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <InputLabel id="company-label">
                <FormattedMessage id="general.Client" defaultMessage="Client" />
              </InputLabel>
              <Select
                labelId="company-label"
                label={
                  <FormattedMessage
                    id="general.Client"
                    defaultMessage="Client"
                  />
                }
                name="company"
                onChange={(event: any) => {
                  const company = companiesMap[event.target.value];
                  if (company) {
                    setProject({
                      ...project,
                      company,
                    });
                  } else {
                    setProject({
                      ...project,
                      company: null,
                    });
                  }
                }}
                value={project.company?.id || ""}
              >
                <MenuItem value={0}>
                  <FormattedMessage
                    id="general.Select-client"
                    defaultMessage="Select client"
                  />
                </MenuItem>
                {companies.map((company) => {
                  return (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <InputLabel id="default_invoicing_scheme-label">
                <FormattedMessage
                  id="general.Default-invoicing-scheme"
                  defaultMessage="Default invoicing scheme"
                />
              </InputLabel>
              <Select
                labelId="default_invoicing_scheme-label"
                label={intl.formatMessage({
                  id: "general.Default-invoicing-scheme",
                  defaultMessage: "Default invoicing scheme",
                })}
                name="default_invoicing_scheme"
                onChange={(event) => handleOnChangeFormField(event)}
                value={project.default_invoicing_scheme || "open"}
                error={
                  showFormErrors && project.default_invoicing_scheme === ""
                }
              >
                <MenuItem value="Fixed">
                  <FormattedMessage id="general.Fixed" defaultMessage="Fixed" />
                </MenuItem>
                <MenuItem value="Hourly">
                  <FormattedMessage
                    id="general.Hourly"
                    defaultMessage="Hourly"
                  />
                </MenuItem>
                <MenuItem value="Cost">
                  <FormattedMessage id="general.Cost" defaultMessage="Cost" />
                </MenuItem>
                <MenuItem value="None">
                  <FormattedMessage id="general.None" defaultMessage="None" />
                </MenuItem>
              </Select>
            </FormControl>
            {renderTrelloBoards()}
            <FormControl
              variant="outlined"
              sx={{ marginTop: "10px" }}
              fullWidth
            >
              <Autocomplete
                multiple
                id="select-developers"
                disableCloseOnSelect={true}
                options={allDevs}
                getOptionLabel={(dev) => {
                  return dev.email;
                }}
                value={project.developers}
                onChange={(event: any, newValue) => {
                  if (event.key === "Backspace") return null;

                  setProject({
                    ...project,
                    developers: newValue,
                  });
                  setHasDevsChanges(true);
                }}
                // renderTags={() => null}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "general.Add-developers",
                      defaultMessage: "Add developers",
                    })}
                    placeholder={intl.formatMessage({
                      id: "general.Filter-developers-by-email",
                      defaultMessage: "Filter developers by email",
                    })}
                  />
                )}
              />
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) => {
                      setProject({
                        ...project,
                        archived: event.target.checked,
                      });
                    }}
                    checked={project.archived}
                  />
                }
                label={intl.formatMessage({
                  id: "general.Archived",
                  defaultMessage: "Archived",
                })}
              />
            </FormGroup>
          </DialogContent>
          {renderDialogActions()}
        </Box>
      </Dialog>
      <ConfirmDialog
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmHandler={() => {
          handleDelete(currProject.id);
        }}
        text={intl.formatMessage({
          id: "project-modal.Do-you-really-want-to-delete-project",
          defaultMessage: "Do you really want to delete this project?",
        })}
      />
    </div>
  );
};

export default Modal;
